<template>
    <img src="./logo.png" alt="Logo" class="icon-size" />
</template>

<script>
export default {
    name: "EthosIcon",
};
</script>

<style scoped>
.icon-size {
    width: 100%; /* or a fixed width, e.g., 50px */
    height: auto; /* to maintain aspect ratio */
    max-width: 100px; /* to ensure it doesn't exceed a maximum */
}
</style>