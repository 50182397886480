var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"d-flex justify-space-between flex-column flex-grow-1"},[_c('div',{staticClass:"mt-n4"},[_c('h6',{staticClass:"text-h6 pb-4"},[_vm._v("Choose an install type")]),_c('div',{staticClass:"text-body-1"},[_c('p',[_vm._v(" This installer can help you update from an older version of "+_vm._s(_vm.$root.$data.OS_NAME)+" or switch to it from another OS, but you need to choose which one you want. ")])])]),_c('div',{staticClass:"d-flex flex-wrap justify-space-around"},[_c('v-card',{staticClass:"ma-4 d-flex flex-column",class:_vm.$root.$data.installType === 'clean'
                        ? 'v-card--selected'
                        : null,attrs:{"outlined":"","max-width":"16rem","ripple":"","color":_vm.$root.$data.installType === 'clean'
                        ? 'primary'
                        : null},on:{"click":function($event){return _vm.showWarningDialog()}}},[_c('v-card-title',{staticClass:"mt-n2"},[_c('v-icon',{staticClass:"pr-2 py-2",attrs:{"color":"rgba(0, 0, 0, 0.87)"}},[_vm._v("mdi-cellphone-erase")]),_vm._v(" Clean install")],1),_c('v-card-subtitle',[_vm._v("Switch from the stock OS or another ROM to "+_vm._s(_vm.$root.$data.OS_NAME)+". "),_c('strong',{staticClass:"red--text text--darken-2"},[_vm._v("All data on your device will be lost.")])])],1),_c('v-card',{staticClass:"ma-4 d-flex flex-column justify-space-between",class:_vm.$root.$data.installType === 'update'
                        ? 'v-card--selected'
                        : null,attrs:{"outlined":"","max-width":"16rem","ripple":"","color":_vm.$root.$data.installType === 'update'
                        ? 'primary'
                        : null},on:{"click":function($event){return _vm.setType('update')}}},[_c('div',[_c('v-card-title',{staticClass:"mt-n2"},[_c('v-icon',{staticClass:"pr-2 py-2",attrs:{"color":"rgba(0, 0, 0, 0.87)"}},[_vm._v("mdi-update")]),_vm._v(" Update")],1),_c('v-card-subtitle',[_vm._v("Update from an older version of "+_vm._s(_vm.$root.$data.OS_NAME)+". Your data won’t be affected. ")])],1)])],1),_c('div',{staticClass:"d-flex justify-space-between flex-row-reverse"},[_c('v-btn',{attrs:{"color":"primary","elevation":"2","rounded":"","x-large":"","disabled":_vm.$root.$data.installType === null},on:{"click":function($event){return _vm.$bubble('nextStep')}}},[_vm._v("Next "),_c('v-icon',{attrs:{"dark":"","right":""}},[_vm._v("mdi-arrow-right")])],1),_c('v-btn',{attrs:{"rounded":"","x-large":"","text":""},on:{"click":function($event){return _vm.$bubble('prevStep')}}},[_vm._v("Back")])],1),_c('v-dialog',{attrs:{"persistent":""},model:{value:(_vm.showWarning),callback:function ($$v) {_vm.showWarning=$$v},expression:"showWarning"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"text-h6"},[_vm._v(" Warning ")]),_c('v-card-text',[_c('p',[_c('strong',{staticClass:"red--text text--darken-2"},[_vm._v("All data on your device will be lost. If you have been using the ethOS System Wallet from previous versions, back it up or transfer out all tokens/funds before doing a clean install.")])])]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.showWarning = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.showWarning = false; _vm.setType('clean')}}},[_vm._v(" Continue ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }